import { Base64 } from "js-base64";
import Cookies from "js-cookie";
import { isBasf, isLocal } from "../config/urlConfig";
import { getClient } from "../config";

const _cookies = {
  getItem: (name, parseJson = true) => {
    try {
      const encoded = Cookies.get(name);
      if (encoded) {
        return parseJson
          ? JSON.parse(Base64.decode(encoded))
          : Base64.decode(encoded);
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error on getting cookie param:", name);
    }
  },
  setItem: (name, value, stringifyJson = true) => {
    try {
      const migrationDate = new Date("2024-04-07T23:00:00");
      const now = new Date();
      // console.log(
      //   { now, migrationDate },
      //   now > migrationDate,
      //   migrationDate,
      //   `.${getClient().toLowerCase()}.cadernovirtual.com.br`,
      //   window.location.hostname
      // );
      const encoded = stringifyJson
        ? Base64.encode(JSON.stringify(value))
        : Base64.encode(value);
      const options =
        isBasf && !isLocal
          ? {
              domain: `.basf-academiaagro.com.br`,
              expires: 90,
            }
          : {
              ...(now > migrationDate
                ? {
                    ...(process.env.NODE_ENV !== "development"
                      ? {
                          domain: `.${window.location.hostname}`,
                        }
                      : {}),
                    expires: 3,
                  }
                : { expires: migrationDate }),
              // expires: 90,
            };
      Cookies.set(name, encoded, options);
    } catch (error) {
      console.error("Error on setting cookie param:", name);
    }
  },
  remove: (name) => {
    const options =
      isBasf && !isLocal ? { domain: `.basf-academiaagro.com.br` } : null;
    Cookies.remove(name, options);
    console.log("removeu cookie", name, options);
  },
  removeAll: () => {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      var neededAttributes = {
        domain: `.basf-academiaagro.com.br`,
      };
      Cookies.remove(cookieName, neededAttributes);
    });
  },
};

export default _cookies;
