import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  FINISH_ATTEMPT,
  GET_FULL_RESEARCH,
  START_ATTEMPT,
  SURVEYS_LIST,
  finishResearchAttempt,
  getFullResearch,
  listaPesquisas,
  startResearchAttempt,
} from "../../../shared/store/survey";
import { Question as NewQuestionComponent } from "../../../features/questions/components/Question";
import { surveyQuestionTemplates } from "../../../features/survey/utils/surveyQuestionTemplates";
import Question from "../Course/pages/EditCourse/Evaluation/Question";
import { questionTypes } from "../Course/pages/EditCourse/Evaluation/questionCategories";
import Loading from "../../components/Loading";
import { useQuery } from "../../../shared/hooks/useQuery";
import { cleanHTML } from "../../../shared/utils/cleanHTML";
import RichText from "../../components/RichText";

function Survey({
  auth,
  surveys,
  research,
  attempt,
  requestsStatus,
  getFullResearch,
  startResearchAttempt,
  finishResearchAttempt,
  listaPesquisas,
  login = false,
  surveyId = null,
  onCancel = () => {},
  onFinish = () => {},
}) {
  const params = useParams();
  const navigate = useNavigate();

  const [answers, setAnswers] = useState([]);
  const [notAnsweredMandatoryQuestions, setNotAnsweredMandatoryQuestions] =
    useState([]);
  const [finished, setFinished] = useState(false);

  const byLinkParam = useQuery()?.get("isByLink");

  const isSurveyByLink = byLinkParam === "true";

  const researchId = params?.id || surveyId;
  const attemptId = attempt?.id || null;

  useEffect(() => {
    if (isSurveyByLink && !!auth?.token && !!auth?.user?.alunoid) {
      listaPesquisas(auth.user.alunoid, auth.token);
    }
  }, [isSurveyByLink, auth]);

  useEffect(() => {
    if (!!researchId && !!auth.user?.alunoid) {
      getFullResearch(researchId, auth.user?.alunoid);
      startResearchAttempt(Number(researchId), auth.user?.alunoid, auth.token);
    }
  }, [researchId, getFullResearch, startResearchAttempt, auth.user?.alunoid]);

  const researchInfo = surveys?.find(
    (survey) => survey.pesquisaid === Number(researchId)
  );

  function getStringAnswer(answer, question) {
    if (question.newQuestionType === "upload") {
      return answer?.join(",");
    }
    if (
      question.category === "discursive" ||
      question.category === "autoCompleteList" ||
      (question.type === "quantity" && question.listOptions.length === 1)
    ) {
      return String(answer);
    }

    return (
      question.listOptions?.[Number(answer ?? "null")]?.optionDescription ?? ""
    );
  }

  const onSubmit = async () => {
    const answersArray = answers.map((value, index) => ({
      questionId: research.questions[index].questionId,
      answer: getStringAnswer(value, research.questions[index]),
    }));

    const notAnsweredQuestions = research.questions?.reduce(
      (currentArray, _, index) => {
        if (
          answers?.[index] === undefined &&
          research.questions[index].mandatory == "S"
        ) {
          return [...currentArray, index + 1];
        }

        return currentArray;
      },
      []
    );
    setNotAnsweredMandatoryQuestions(notAnsweredQuestions);
  
    if (notAnsweredQuestions?.length > 0) {
      alert(
        `Você ainda não respondeu a todas as perguntas.\nResponda à(s) questão(ões):\n${notAnsweredQuestions?.join(
          "\n"
        )}`
      );

      return;
    }

    await finishResearchAttempt(attemptId, answersArray);

    setFinished(true);
  };

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          padding: 1,
          overflow: "scroll",
          height: login ? "100%" : "calc(100vh - 64px)",
          width: "100%",
          [theme.breakpoints.down("sm")]: {
            paddingBottom: "3rem",
          },
        }}
        maxWidth="unset"
      >
        <Box
          sx={{
            marginY: "0.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginX: "auto",
          }}
          maxWidth="1200px"
        >
          <Box sx={{ paddingX: "1rem" }}>
            <Typography variant="h6">{research?.title}</Typography>
            <RichText value={research?.orientations} />
          </Box>

          {research?.questions?.length > 0 &&
            research.questions.map((question, index) => {
              if (question.newQuestionType == "upload") {
                return (
                  <NewQuestionComponent
                    error={
                      question.mandatory &&
                      notAnsweredMandatoryQuestions.some(
                        (position) => position == index + 1
                      )
                    }
                    label={`Questão ${index + 1} de ${
                      research?.questions?.length
                    }`}
                    key={`question-${index}`}
                    submittedAnswer={answers?.[index]}
                    initialQuestionData={question}
                    questionTemplates={surveyQuestionTemplates}
                    setSubmittedAnswer={(newValue) => {
                      setAnswers((prev) => {
                        const newAnswers = [...prev];
                        newAnswers[index] = newValue;
                        return newAnswers;
                      });
                    }}
                    attemptId={attemptId}
                  />
                );
              }
              return (
                <Question
                  error={
                    question.mandatory &&
                    notAnsweredMandatoryQuestions.some(
                      (position) => position == index + 1
                    )
                  }
                  key={`question-${index}`}
                  value={answers?.[index]}
                  onChange={(event) => {
                    const newValue = event?.target?.value ?? event;

                    setAnswers((prev) => {
                      const newAnswers = [...prev];
                      newAnswers[index] = newValue;
                      return newAnswers;
                    });
                  }}
                  question={question}
                  index={index}
                  evaluation={research}
                  questionTypes={questionTypes}
                  exhibition
                />
              );
            })}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              gap: "2rem",
            }}
          >
            <Button
              color="error"
              variant="contained"
              onClick={
                login
                  ? onCancel
                  : () => navigate(isSurveyByLink ? "/" : "/pesquisa")
              }
            >
              Cancelar
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={
                research?.mandatory === "L" && research?.numberOfAttempts > 0
              }
            >
              {research?.mandatory === "L" && research?.numberOfAttempts > 0
                ? "Respondido"
                : "Enviar Respostas"}
            </Button>
          </Box>
        </Box>
      </Box>

      <Dialog open={finished}>
        <DialogContent>
          <Typography>Resposta registrada com sucesso!</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={
              login
                ? onFinish
                : () => navigate(isSurveyByLink ? "/" : "/pesquisa")
            }
          >
            {isSurveyByLink ? "Fechar pesquisa" : "OK"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={
          requestsStatus[SURVEYS_LIST] === "loading" ||
          requestsStatus[GET_FULL_RESEARCH] === "loading" ||
          requestsStatus[START_ATTEMPT] === "loading" ||
          requestsStatus[FINISH_ATTEMPT] === "loading"
        }
      >
        <Box sx={{ position: "relative", width: "5rem", height: "5rem" }}>
          <Loading />
        </Box>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  surveys: state.survey.surveys,
  research: state.survey.currentResearch,
  attempt: state.survey.attemptInfo,
  requestsStatus: state.survey.status,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getFullResearch,
      startResearchAttempt,
      finishResearchAttempt,
      listaPesquisas,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Survey);
