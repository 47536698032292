import { useContext } from "react";
import { QuestionContext } from "./Question";
import RichText from "../../../ui/components/RichText";

export default function Discursive() {
  const { isEditing } = useContext(QuestionContext);
  return (
    <>
      <RichText isEditing={!isEditing} />
    </>
  );
}
