import { Button } from "@mui/material";
import OneSignal from "react-onesignal";
import { useEffect, useMemo } from "react";
import { useOneSignalAutoLogin, useOneSignalInit } from "./index";
import _cookies from "../../utils/_cookies";
import { useSelector } from "react-redux";

export default function PushTest() {
  const handleLog = () => {
    console.log(OneSignal.User.PushSubscription);
  };
  const handleLogin = () => {
    console.log(_cookies.getItem("auth")?.user?.alunoid);
    OneSignal.login(btoa(22553));
  };
  const handleLogout = () => {
    OneSignal.logout();
  };
  const handleShowPrompt = () => {
    OneSignal.Slidedown.promptPush({
      force: true,
      slidedownPromptOptions: { force: true },
    });
  };
  const handleOptIn = () => {
    OneSignal.User.PushSubscription.optIn();
  };

  const handleOptOut = () => {
    OneSignal.User.PushSubscription.optOut();
  };

  const handlePushTest = () => {
    console.log(OneSignal.User.PushSubscription);
    console.log(OneSignal.Slidedown.promptPush());
    console.log(OneSignal.User);
    console.log(OneSignal.Notifications);
    console.log(OneSignal.Debug);
  };
  return (
    <>
      <Button onClick={handleShowPrompt}>Prompt</Button>
      <Button onClick={handleLog}>console log user</Button>
      <Button onClick={handleLogin}>login</Button>
      <Button onClick={handleLogout}>logout</Button>
      <Button onClick={handleOptIn}>opt in</Button>
      <Button onClick={handleOptOut}>opt ou</Button>
      <Button onClick={handlePushTest}>teste</Button>
    </>
  );
}

export function OneSignalProvider({ children }) {
  useOneSignalInit();
  useOneSignalAutoLogin();
  return (
    <>
      {children}
      {/* Rest of your page content */}
    </>
  );
}
