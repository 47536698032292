import axios from "axios";
import { getClient } from "../config/urlConfig";

export const SAVE_EVALUATION = "saveEvaluation";
export const SAVE_EVALUATION_CONFIGURATION = "saveEvaluationConfiguration";
export const SAVE_QUESTION = "saveQuestion";
export const GET_FULL_EVALUATION = "getFullEvaluation";
export const GET_EVALUATION_HEADER = "getEvaluationHeader";
export const GET_EVALUATION_QUESTIONS = "getEvaluationQuestions";
export const START_ATTEMPT = "startAttempt";
export const FINISH_ATTEMPT = "finishAttempt";
export const GET_RESULTS = "getResults";
export const IN_EDITING = "isEdited";
export const SET_STATUS = "setStatus";
const ATTEMPT_COUNTDOWN = "ATTEMPT_COUNTDOWN";
const baseUrl = "https://cadernovirtual.inf.br/survey-service";
const oldBaseUrl = "https://middleware.cadernovirtual.com.br/aluno.asmx";

const domain = getClient();

const surveyApi = {
  saveEvaluation: (payload) =>
    axios.post(`${baseUrl}/survey`, payload, {
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  saveQuestion: (payload) =>
    axios.post(`${baseUrl}/surveyQuestions`, payload, {
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  saveEvaluationConfiguration: (payload) =>
    axios.post(`${baseUrl}/survey/v2/save`, payload, {
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  getFullEvaluation: (evaluationId, isEditing) =>
    axios.get(`${baseUrl}/survey/fullSurvey`, {
      params: { id: evaluationId, isEditing },
      data: {},
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  getEvaluationHeader: (evaluationId) =>
    axios.get(`${baseUrl}/survey`, {
      params: { id: evaluationId },
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  getEvaluationQuestions: (evaluationId) =>
    axios.get(`${baseUrl}/surveyQuestions`, {
      params: { surveyId: evaluationId },
      headers: { "Access-Control-Allow-Origin": "*", domain },
    }),
  startAttempt: (surveyId, studentId, enrollmentId, token) => {
    const payload = {
      surveyId: surveyId,
      studentId: studentId,
      enrollmentId,
    };
    return axios.post(`${baseUrl}/survey/startSurvey`, payload, {
      headers: { token, domain, "Access-Control-Allow-Origin": "*" },
    });
  },
  finishAttempt: (attemptId, answersArray) =>
    axios.post(
      `${baseUrl}/survey/answer`,
      {
        surveyAttemptsId: attemptId,
        attempts: answersArray,
      },
      {
        headers: { "Access-Control-Allow-Origin": "*", domain },
      }
    ),
  getResults: (surveyId, enrollmentId, token) =>
    axios.get(`${baseUrl}/survey/result`, {
      headers: { "Access-Control-Allow-Origin": "*", token, domain },
      params: { surveyId: surveyId, registerId: enrollmentId },
      data: {},
    }),
};

export const saveEvaluation = (evaluation) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_EVALUATION, value: "loading" },
    });

    const payload = {
      ...evaluation,
      title: "Título teste",
      type: "CAP",
      orientation:
        "Responder as pesquisas preenchendo e escolhendo as opções de múltipla escolha e discursivas, de acordo com o solicitado",
    };

    const response = await surveyApi.saveEvaluation(payload);

    dispatch({
      type: SAVE_EVALUATION,
      payload: response.data.object,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_EVALUATION, value: "idle" },
    });
  }
};

export const saveEvaluationConfiguration = (evaluation) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_EVALUATION_CONFIGURATION, value: "loading" },
    });
    const payload = {
      ...evaluation,
      title: "Título teste",
      type: "CAP",
      showResults:
        evaluation.showResults == "S" || evaluation.showResults == true,
      orientation:
        "Responder as pesquisas preenchendo e escolhendo as opções de múltipla escolha e discursivas, de acordo com o solicitado",
    };
    const response = await surveyApi.saveEvaluationConfiguration(payload);
    dispatch({
      type: SAVE_EVALUATION,
      payload: response.data.object,
    });
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_EVALUATION_CONFIGURATION, value: "success" },
    });
  } catch (error) {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_EVALUATION_CONFIGURATION, value: "error" },
    });
  } finally {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_EVALUATION_CONFIGURATION, value: "idle" },
    });
  }
};

export const saveQuestion = (question) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_QUESTION, value: "loading" },
    });
    const payload = {
      ...(question ? question : {}),
      listOptions: question.listOptions.filter((object) => {
        return (
          object.remove ||
          object.edited ||
          !object.id ||
          question.remove ||
          question.isRegisteredQuetion
        );
      }),
    };
    const response = await surveyApi.saveQuestion(payload);
    dispatch({
      type: SAVE_QUESTION,
      payload: response.data.object,
      index: question.index,
    });
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_QUESTION, value: "success" },
    });
  } catch (error) {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_QUESTION, value: "error" },
    });
    throw new Error(error);
  } finally {
    dispatch({
      type: SET_STATUS,
      payload: { key: SAVE_QUESTION, value: "idle" },
    });
  }
};

export const getFullEvaluation =
  (surveyId, isEditing = false) =>
  async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: GET_FULL_EVALUATION, value: "loading" },
      });

      const response = await surveyApi.getFullEvaluation(surveyId, isEditing);

      dispatch({
        type: GET_FULL_EVALUATION,
        payload: response.data.object[0],
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: GET_FULL_EVALUATION, value: "idle" },
      });
    }
  };

export const startAttempt =
  (surveyId, studentId, enrollmentId, token) => async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: START_ATTEMPT, value: "loading" },
      });

      const response = await surveyApi.startAttempt(
        surveyId,
        studentId,
        enrollmentId,
        token
      );

      dispatch({
        type: START_ATTEMPT,
        payload: response.data.object,
      });
      dispatch(handleCountdownStart());
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: START_ATTEMPT, value: "idle" },
      });
    }
  };

export const finishAttempt = (attemptId, answersArray) => async (dispatch) => {
  try {
    dispatch({
      type: SET_STATUS,
      payload: { key: FINISH_ATTEMPT, value: "loading" },
    });

    const response = await surveyApi.finishAttempt(attemptId, answersArray);

    dispatch({
      type: FINISH_ATTEMPT,
      payload: response.data.object,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({
      type: SET_STATUS,
      payload: { key: FINISH_ATTEMPT, value: "idle" },
    });
  }
};

export const handleCountdownStart = () => async (dispatch) => {
  try {
    dispatch({
      type: ATTEMPT_COUNTDOWN,
      payload: false,
    });
  } catch (error) {
  } finally {
  }
};

export const handleCountdownEnd = () => async (dispatch) => {
  try {
    dispatch({
      type: ATTEMPT_COUNTDOWN,
      payload: true,
    });
  } catch (error) {
  } finally {
  }
};
export const getResults =
  (surveyId, enrollmentId, token) => async (dispatch) => {
    try {
      dispatch({
        type: SET_STATUS,
        payload: { key: GET_RESULTS, value: "loading" },
      });

      const response = await surveyApi.getResults(
        surveyId,
        enrollmentId,
        token
      );

      let objectJson = undefined;
      if (response.status == 200 && response.data.status == 200) {
        objectJson = response.data.object;
      }

      dispatch({
        type: GET_RESULTS,
        payload: objectJson,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({
        type: SET_STATUS,
        payload: { key: GET_RESULTS, value: "idle" },
      });
    }
  };

export const setEvaluationEditingStatus = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_STATUS,
      payload: { key: IN_EDITING, value: value },
    });
  };
};

const INITIAL_STATE = {
  currentEvaluation: {},
  attemptInfo: {},
  results: {},
  status: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_EVALUATION:
      return { ...state, currentEvaluation: action.payload };
    case SAVE_QUESTION:
      const questions = state.currentEvaluation.questions || [];

      questions.splice(action.index, 1, { ...action.payload });

      return {
        ...state,
        currentEvaluation: { ...state.currentEvaluation, questions },
      };
    case SAVE_EVALUATION_CONFIGURATION:
      return { ...state, currentEvaluation: action.payload };
    case GET_FULL_EVALUATION:
      return { ...state, currentEvaluation: action.payload, attemptInfo: {} };
    case START_ATTEMPT:
      return { ...state, attemptInfo: action.payload };
    case FINISH_ATTEMPT:
      return { ...state, attemptInfo: {}, results: action.payload };
    case GET_RESULTS:
      return { ...state, results: action.payload };

    case ATTEMPT_COUNTDOWN:
      return {
        ...state,
        attemptInfo: {
          ...state.attemptInfo,
          time: {
            running: true,
            ended: action.payload,
          },
        },
      };
    case SET_STATUS:
      const newStatus = { ...state.status };
      const { key, value } = action.payload;

      newStatus[key] = value;

      return { ...state, status: newStatus };
    default:
      return state;
  }
}
