import { Box } from "@mui/material";
import { useProgressiveImage } from "../../shared/hooks/useProgressiveImage";
import { resourcesUrl } from "../../shared/config";
function ImageWithBackdrop({ url }) {
  const loaded = useProgressiveImage(url);
  const placeholder = resourcesUrl + "/fotos/CURSO_semfoto.jpg";
    return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          objectFit: "cover",
        }}
        >
        <img
          style={{
            width: "100%",
            height: "100%",
            transform: "scale(1.25)",
            filter: "blur(25px) brightness(0.9)",
            backgroundColor: "rgba(0,0,0,0.65)",
          }}
          loading="lazy"
          src={loaded || placeholder}
        />
      </Box>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          // zIndex: "2",
        }}
      >
        <img
          style={{
            height: "100%",
            maxWidth: "100%",
            display: "block",
            margin: "auto",
            objectFit: "contain",
            maxHeight: "100%",
          }}
          loading="lazy"
          src={loaded || placeholder}
        />
      </Box>
    </Box>
  );
}

export default ImageWithBackdrop;
