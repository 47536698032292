import { useState, useEffect } from "react";

export const useProgressiveImage = (src) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
    return () => {
      setSourceLoaded(null);  
    };
  }, [src]);

  return sourceLoaded;
};
