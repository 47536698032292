import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import "./richEditor.css";
import { Box } from "@mui/material";

export default function RichTextContent({ value, onChange }) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",

    "color",
    "background",

    "list",
    "indent",

    "bullet",

    "link",
    // "image",
  ];
  return (
    <Box
      sx={{
        "& .ql-editor": {
          height: "unset",
          minHeight: "unset",
          padding: "0",
        },
      }}
    >
      <ReactQuill
        readOnly
        // defaultValue={""}
        value={value}
        theme={"bubble"}
        // modules={{ toolbar: ["bold"] }}
        formats={formats}
        modules={modules}
      />
    </Box>
  );
}
