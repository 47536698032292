import {
  MOVE_CHAPTER_UP,
  MOVE_CHAPTER_DOWN,
} from "../../../../../shared/store/editing";

// actions
export const COURSE_DADOS_DISCIPLINA = "COURSE_DADOS_DISCIPLINA";
export const COURSE_LISTA_CAPITULOS = "COURSE_LISTA_CAPITULOS";
export const COURSE_INSERE_MATRICULA = "COURSE_INSERE_MATRICULA";
export const COURSE_INICIAR_TRILHA = "COURSE_INICIAR_TRILHA";
export const COURSE_LOGON = "COURSE_LOGON";
export const COURSE_LOGOUT = "COURSE_LOGOUT";
export const COURSE_LIST_VIEWED = "COURSE_LIST_VIEWED";
export const COURSE_CERTIFICADO_PROPRIO = "COURSE_CERTIFICADO_PROPRIO";
export const COURSE_CLEAR_CERTIFICATE = "COURSE_CLEAR_CERTIFICATE";
export const COURSE_SET_CURRENT_COURSE = "COURSE_SET_CURRENT_COURSE";
export const COURSE_CLEANUP = "COURSE_CLEANUP";
export const COURSE_STUDENT_ENROLLMENT_FETCHED =
  "COURSE_STUDENT_ENROLLMENT_FETCHED";
export const CHANGE_STATUS_COURSE = "CHANGE_STATUS_COURSE";
export const COURSE_TOGGLE_LIBERADO = "COURSE_TOGGLE_LIBERADO";

const changeChapterPositions = (chapters, initial, target) => {
  const reorderedChapters = [...chapters];
  const movedChapter = reorderedChapters.splice(initial - 1, 1);
  reorderedChapters[initial - 1].ordem = initial;
  reorderedChapters.splice(target - 1, 0, {
    ...movedChapter[0],
    ordem: target,
  });
  return reorderedChapters;
};

const INITIAL_VALUE = { chapters: [] };

// reducer
export default function reducer(state = INITIAL_VALUE, action) {
  switch (action.type) {
    case COURSE_DADOS_DISCIPLINA:
      return { ...state, details: action.details };

    case COURSE_LISTA_CAPITULOS:
      return { ...state, chapters: action.chapters };

    case COURSE_INSERE_MATRICULA:
      const newState = { ...state };
      if (state.details) state.details.matricula_ativa = action.matriculaid;
      return newState;

    case COURSE_LOGON:
      return { ...state, logonid: action.logonid };

    case COURSE_LOGOUT:
      return { ...state, logonid: action.logonid };

    case CHANGE_STATUS_COURSE:
      return { ...state, updatedCourse: action.updatedCourse };

    case COURSE_LIST_VIEWED:
      const newChapters = [...state.chapters];

      const index = newChapters?.findIndex(
        (chapter) => chapter.capituloid === Number(action.chapterId)
      );

      if (index > -1) newChapters[index].views = action.payload;

      return { ...state, chapters: newChapters };

    case COURSE_CERTIFICADO_PROPRIO:
      const newCertificates = state.certificates ?? [];

      if (
        newCertificates?.find(
          (certificate) => certificate.id === action.certificate.id
        )
      ) {
        newCertificates[
          newCertificates.findIndex(
            (certificate) => certificate.id === action.certificate.id
          )
        ] = action.certificate;
      } else {
        newCertificates.push(action.certificate);
      }
      return { ...state, certificates: newCertificates };

    case COURSE_STUDENT_ENROLLMENT_FETCHED:
      return { ...state, enrollment: action.payload };

    case COURSE_CLEAR_CERTIFICATE:
      return { ...state, certificate: null };

    case COURSE_SET_CURRENT_COURSE:
      return { ...state, currentCourse: action.currentCourse };
    case COURSE_TOGGLE_LIBERADO:
      return {
        ...state,
        details: {
          ...state.details,
          LIBERADO: state.details.LIBERADO === "S" ? "N" : "S",
        },
      };
    case MOVE_CHAPTER_DOWN:
      return {
        ...state,
        chapters: changeChapterPositions(
          state.chapters,
          action.payload,
          action.payload + 1
        ),
      };
    case MOVE_CHAPTER_UP:
      return {
        ...state,
        chapters: changeChapterPositions(
          state.chapters,
          action.payload - 1,
          action.payload
        ),
      };
    case COURSE_CLEANUP:
      return INITIAL_VALUE;
    default:
      return state;
  }
}
