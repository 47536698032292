//const localhostClient = "cvcorporate";
// const localhostClient = "unimarche";
const localhostClient = "template";
// const localhostClient = "linea";

export const isLocal =
  window.location.origin.search(/(localhost)|(([0-9]+.)+:[0-9]+)/g) > -1;

//export const isStMarche = window.location.host.search(/unimarche/) > -1;
export const isStMarche =
  (isLocal && localhostClient === "unimarche") ||
  window.location.host.search(/unimarche/) > -1;

export const isPiracanjuba =
  (isLocal && localhostClient === "piracanjuba") ||
  window.location.host.search(/piracanjuba/) > -1;

export const isTemplate =
  (isLocal && localhostClient === "template") ||
  window.location.host.search(/template/) > -1;

export const isTestes = window.location.host.search(/testes/) > -1;

export const isBeta = window.location.host.search(/beta/) > -1;

export const getClient = () => {
  return isStMarche
    ? "UNIMARCHE"
    : isTestes
    ? "CVCORPORATE"
    : getClient2().toUpperCase().replace("beta.", "").replace(".", "");
};

export const getClient2 = () => {
  const host = window.location.host;
  const client = isLocal
    ? localhostClient
    : host
        .replace("www.", "")
        .replace("cadernovirtual", "")
        .replace("basf-academiaagro", "")
        .replace(".com.br", "")
        .replace(".", "")
        .replace("beta", "");

  return client || "cooperar";
};
export const isBasf =
  getClient() === "cooperar" ||
  getClient() === "aplicoucolheu" ||
  getClient() === "pontoaponto" ||
  window.location.host.search(/basf-academiaagro/) > -1;
