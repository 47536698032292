import { api } from "../../api";

import { TRACKS_TRILHAS, TRACKS_STATUS } from "./trackReducer";
import { getTimeUsed } from "../../../shared/utils/timeDif";

// action creators
export const getTracks = ({ alunoid, token }) => {
  return async (dispatch) => {
    dispatch({ type: TRACKS_STATUS, payload: "loading" });
    try {
      const res = await fetch(`${api.trilhas}?alunoid=${alunoid}`, {
        method: "get",
        headers: { token },
      });
      if (res.status >= 200 && res.status < 300) {
        const json = await res.json();
        const tracks = json[0].data;
        dispatch({ type: TRACKS_TRILHAS, payload: tracks });
        dispatch({ type: TRACKS_STATUS, payload: "success" });
      } else {
        throw new Error(res.statusText);
      }
    } catch (error) {
      dispatch({ type: TRACKS_STATUS, payload: "error" });
      throw new Error(error);
    }
  };
};

export const filterTracks = (tracks, filters) => {
  const emAndamento = tracks.filter((track) => {
    return track.SITUACAO == "Em Andamento";
  });
  const naoIniciadas = tracks.filter(
    (track) => track.SITUACAO == "Não Iniciada"
  );
  const concluidas = tracks.filter((track) => track.SITUACAO == "Conclusa");
  const expiradas = tracks.filter((track) => track.SITUACAO == "Expirada");

  const filteredTracks = [
    ...naoIniciadas.map((track) => ({
      ...track,
      hidden: !filters[0].isActive,
    })),
    ...emAndamento.map((track) => ({
      ...track,
      hidden: !filters[1].isActive,
    })),
    ...concluidas.map((track) => ({
      ...track,
      hidden:
        !filters[2].isActive &&
        !(
          !naoIniciadas.length &&
          filters[0].isActive &&
          !emAndamento.length &&
          filters[1].isActive
        ),
    })),
    ...expiradas.map((track) => ({
      ...track,
      hidden:
        !filters[3].isActive &&
        !(
          !naoIniciadas.length &&
          filters[0].isActive &&
          !emAndamento.length &&
          filters[1].isActive &&
          !concluidas.length
        ),
    })),
  ];
  return filteredTracks;
};
